<template>
  <div class="view flex flex-d ai-c">
    <headers styleSize="min" :showTime="false"></headers>
    <img src="@/assets/speaial_activity_img/image/time_title.png" class="time-title" alt="">
    <img src="@/assets/speaial_activity_img/image/eleventitle.png" class="eleven-title" alt="">
    <div class="main">
      <div class="hint">
        <p>免责声明：本图中的数据是由星图自</p>
        <p>主研发的数据记录系统以消费者视角</p>
        <p>记录页面信息生成，星图数据承诺在</p>
        <p>信息汇总，加工，整理的全过程中保</p>
        <p>持客观中立的立场，星图数据拥有本</p>
        <p>数据最终解释权。</p>
      </div>
      <div class="code-item flex flex-d ai-c">
        <div class="text-view">
          <div class="dian"></div>
          <p class="text">手机端也能看“星图数据2023年双十一全网销售直播”</p>
        </div>
        <div class="text-view">
          <div class="dian"></div>
          <p class="text">扫码下载弦镜APP观看 11月10日 20:00 开启直播</p>
        </div>
        <img class="code" src="@/assets/speaial_activity_img/image/code-xcx.png" alt="">
        <p class="code-hint">弦镜app下载二维码</p>
      </div>
      <div class="code-item flex flex-d ai-c">
        <div class="text-view">
          <div class="dian"></div>
          <p class="text">关注“星图数据”微信公众号 11月 12日发布 </p>
        </div>
        <div class="text-view">
          <p class="text">《2023 年双十一全网销售数据解读报告》 </p>
        </div>
        <img class="code" src="@/assets/speaial_activity_img/image/code-wb.png" alt="">
        <p class="code-hint">星图数据公众号二维码</p>
      </div>
    </div>
  </div>
</template>

<script>
import Headers from '@/components/Big/Header.vue'

export default {
  components: {
    Headers,
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/rem.scss';
@import './style/zn.scss';
</style>